<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="1000"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(storeOptions ? storeOptions.title : title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                        
                <v-card
                    v-if="storeOptions == null"
                    flat
                >
                    <v-card-text>

                        <div class="form-box-title">{{ $t("Исполнение") }}</div>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Текст") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-textarea
                                    v-model="executionText"
                                    class="oneRowStartTextarea"
                                    rows="1"
                                    auto-grow
                                    row-height="16"
                                    no-resize
                                    hide-details
                                    outlined
                                    dense
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Вложения") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="9">
                                <div class="icon-click-row-group">

                                    <div @click="$refs.executionAttachments.click()">
                                        <input
                                            type="file"
                                            ref="executionAttachments"
                                            @change="onExecutionAttachmentsChange"
                                            style="display: none"
                                            multiple
                                        />
                                        <v-icon small>
                                            fas fa-edit
                                        </v-icon>
                                    </div>
                                    
                                    <div class="cloud-file-list-box">
                                        <div class="cloud-items-container">

                                            <div v-if="files.length == 0" class="cfl-placeholder">{{ $t('Нет_файлов') }}</div>

                                            <v-row 
                                                v-else
                                                dense
                                            >
                                                <v-file-item
                                                    v-for="file in files" :key="file.name"
                                                    :name="file.name"
                                                    :size="file.size"
                                                    col-width="4"
                                                    :hide-load="true"
                                                    @on-delete-attachment="onDeleteAttachment(file)"
                                                />
                                        </v-row>

                                        </div>
                                    </div>                
                                    

                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>

                <v-card
                    flat
                    v-if="$store.getters['actionsource/isDataSourceCommon'] != true || storeOptions != null"
                >
                    <v-card-text
                        v-if="loading"
                    >
                        <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                        >
                            {{ $t("Загрузка...") }}
                        </v-col>

                        <v-col cols="12">
                            <v-progress-linear
                                color="#a94442"
                                indeterminate
                                rounded
                                height="6"
                            ></v-progress-linear>
                        </v-col>

                    </v-card-text>
                    <v-card-text v-else>

                        <div class="form-box-title title-with-right-part">
                            <span>
                                {{ $t("Прикрепить_документ") }}
                            </span>
                            <div class="twrp-content">
                                <v-text-field
                                    v-model="documentSearch"
                                    :placeholder="$t('Поиск')"
                                    hide-details
                                    dense
                                    clearable
                                    @click:clear="documentSearch = null"
                                    class="twrp-search"
                                    append-icon="fas fa-search"
                                >
                                </v-text-field>
                            </div>
                        </div>

                        
                        <v-list
                            v-if="documents.length > 0"
                            class="modal-select-list"
                            dense
                            outlined
                        >
                            <v-list-item-group
                                v-model="document"
                                color="cyan darken-1"
                            >
                                    <v-list-item
                                        v-for="item in documents"
                                        :key="item.id"
                                        :value="item"
                                    >
                                        <!-- Иконка документа--> 
                                        <v-list-item-avatar>
                                            <v-icon>
                                                fas fa-file-alt
                                            </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content v-tooltip.top-center="item.theme">

                                            <v-list-item-title>
                                                {{ item.doctype }} № {{ item.regnumber }} {{ $t("от_время") }} {{ $moment(item.regdate).format("DD.MM.YYYY") }}
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                {{ item.theme }}
                                            </v-list-item-subtitle>

                                        </v-list-item-content>

                                        <v-list-item-icon>
                                            <div @click.stop="onOpenDocumentInNewTab(item)">
                                                <v-icon
                                                    small
                                                    v-tooltip.left-center="$t('Открыть_документ_в_новой_вкладке')"                                                    
                                                >
                                                    fas fa-external-link-square-alt
                                                </v-icon>
                                            </div>
                                        </v-list-item-icon>

                                    </v-list-item>

                            </v-list-item-group>
                            <v-divider></v-divider>
                            <v-pagination
                                v-model="page"
                                :length="Math.ceil(total/perPage)"
                                :total-visible="9"
                                class="cust-pagination"
                                color="teal"
                            ></v-pagination>
                        </v-list>
                        <v-row v-else no-gutters>
                            <v-col cols="12" sm="12" md="12">
                                <div
                                    class="onlyReadData"
                                >
                                    {{ $t("К_сожалению,_по_Вашему_запросу_ничего_не_найдено") }}
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="isOkVisible"
                >
                    {{ $t(storeOptions ? storeOptions.okButtonText : "Добавить_исполнение") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "ExecuterAnswerDlg",
    data () {
        return {
            title: 'Исполнение_поручения',
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('dialogs/executerAnswer',
        {
            visible: 'isVisible',
            loading: 'isLoading',
            documents: 'getDocuments',
            perPage: 'getDocumentPerPage',
            total: 'getDocumentsTotal',
            files: 'getFiles',
            storeOptions: 'getOptions'
        }),
        ...mapGetters('attachments',
        {
            MaxFilesSize: 'getMaxFilesSize'
        }),
        ...mapGetters('actionsource',
        {
            dataSourceType: 'getDataSourceType',
            dataSource: 'getDataSource',
            isMainExecuter: 'isMainExecuterGiveAnswer'
        }),
        executionText: {
            get: function() {
                return this.$store.getters['dialogs/executerAnswer/getExecutionText'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('dialogs/executerAnswer/SET_EXECUTION_TEXT', v); 
            }, 500)
        },
        document: {
            get: function() {
                return this.$store.getters['dialogs/executerAnswer/getSelectedDocument'];
            },
            set: function(v) {
                this.$store.commit('dialogs/executerAnswer/SET_SELECTED_DOCUMENT', v);
            }
        },
        documentSearch: {
            get: function() {
                return this.$store.getters['dialogs/executerAnswer/getDocumentSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.dispatch('dialogs/executerAnswer/changeSearch', v);
            }, 500)
        },
        page: {
            get: function() {
                return this.$store.getters['dialogs/executerAnswer/getDocumentPage'];
            },
            set: function(v) {
                this.$store.dispatch('dialogs/executerAnswer/changePage', v);
            }
        },
        isOkVisible() {
            if (this.storeOptions)
                return this.document != null;

            let isIncomingActItemResolution = this.dataSourceType === "Resolution"
                ? this.dataSource?.Data?.Object?.Resolution?.Document?.Card?.TypeC4 === 3
                : this.dataSource?.Data?.Object?.Document?.Card?.TypeC4 === 3;

            return isIncomingActItemResolution && this.isMainExecuter ? this.executionText.length > 0 && ( this.document != null || this.files?.length > 0 ) : this.executionText.length > 0;
        }
    },
    methods: {
        ...mapActions('dialogs/executerAnswer', ['ok', 'cancel']),
        ...mapActions('attachments', ['validateFile']),
        async onExecutionAttachmentsChange (event) {
            let files = Array.from(event.target.files);            
            let filesToAdd = [];
            let removedFiles = [];
            let totalSize = 0;

            for (let file of files) {
                let checkResult = await this.validateFile(file);

                if (!checkResult.success) {
                    removedFiles.push({ message: checkResult.message, file });
                    continue;
                }

                if (filesToAdd.length == 5) {
                    removedFiles.push({ message: this.$t('Превышен_лимит_количества_вложений'), file });
                    continue;
                }

                if (totalSize + file.size > this.MaxFilesSize) {
                    removedFiles.push({ message: this.$t('Превышен_лимит_размера_вложений'), file });
                    continue;
                }

                totalSize += file.size;
                filesToAdd.push(file);
            }

            removedFiles.forEach(obj => {
                this.$notify.alert(this.$t('Ошибка_загрузки_файла:_filename_with_reason.message', { filename: obj.file.name, reason: obj.message }));
            });

            this.$store.commit('dialogs/executerAnswer/SET_FILES', filesToAdd);
            event.target.value = '';
        },
        onDeleteAttachment(file) {
            let index = this.files.indexOf(file);
            let newFiles = Array.from(this.files);
            newFiles.splice(index, 1);
            this.$store.commit('dialogs/executerAnswer/SET_FILES', newFiles);
        },
        onOpenDocumentInNewTab(document) {

            let routeData = this.$router.resolve({
                name: 'OutgoingDocument',
                query: { id: document.id }
            });

            // временно удаляем идентификатор сессии, для того, что-бы новая вкладка не скопировала его в свое хранилище
            var sessionId = sessionStorage.getItem('SessionId');
            sessionStorage.removeItem('SessionId');

            window.open(routeData.href, '_blank');
            
            // устанавливаем идентификатор сессии
            sessionStorage.setItem('SessionId', sessionId);
        }
    }
}
</script>